'use client';
import { Box } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import { carouselImages } from '@/constants';

const styles = {
  carouselContainer: {
    position: 'absolute',
    width: '100%',
    height: '575px'
  },
  contentStyle: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '575px',
    position: 'relative'
  }
};

export default function Carousel() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Slider {...settings} data-test-id="carousel-container">
      {carouselImages.map((image, index) => (
        <Box key={index} data-test-id={`slide-item-${index}`}>
          <Box sx={styles.contentStyle}>
            <Image
              src={image}
              alt={`Slide image ${index + 1}`}
              fill
              style={{ objectFit: 'cover', objectPosition: 'center' }}
              priority
              sizes="(max-width: 768px) 100vw, 
                     (max-width: 1200px) 100vw, 
                     100vw"
            />
          </Box>
        </Box>
      ))}
    </Slider>
  );
}
