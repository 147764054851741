'use client';
import { Button } from '@mui/material';
import { useRouter } from 'next/navigation';
import React from 'react';

const SearchButton = () => {
  const router = useRouter();
  const goToPrograms = () => {
    router.push('/programs');
  };
  return (
    <Button
      onClick={goToPrograms}
      sx={{
        maxWidth: 432,
        width: '100%'
      }}
      variant="contained"
    >
      Search
    </Button>
  );
};

export default SearchButton;
