import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/ExpandMore.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Accordion/Accordion.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionDetails/AccordionDetails.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionSummary/AccordionSummary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/List/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/ListItem/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Paper/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/TextField/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/public/assets/png/bg-tecture.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/png/blogImage.png");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/call.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/callUs.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/chatbot.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/dots.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/dotted-bg.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/email.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/facebook.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/footer-texture.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/heart-circle.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/like.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/lower-wave.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/mail.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/map.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/message.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/polygon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/quote.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/quoteWhite.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/settings.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/story.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/twitter.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/upper-wave.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/user.svg");
;
import(/* webpackMode: "eager" */ "/app/public/assets/svg/wifi.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/pageComponents/featuredScholarshipSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/pageComponents/programCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/pageComponents/seachDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/pageComponents/searchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/accordian.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/heroCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/muiNavbar/index.tsx");
