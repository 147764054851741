'use client';
import { Autocomplete, Box, TextField } from '@mui/material';
import React from 'react';

const SearchDropdown = ({
  item
}: {
  item: { id: number; title: string; data: { label: string }[] };
}) => {
  return (
    <Box
      sx={{
        maxWidth: '350px',
        width: '100%',
        margin: 'auto'
      }}
    >
      <Autocomplete
        options={item.data}
        renderInput={(params) => (
          <TextField
            {...params}
            label={item.title}
            variant="filled"
            sx={classes.noBorder}
          />
        )}
      />
    </Box>
  );
};

export default SearchDropdown;

const classes = {
  noBorder: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F7F8F9',
      borderRadius: '8px',
      '&:before': {
        borderBottom: 'none'
      },
      '&:hover:before': {
        borderBottom: 'none !important' // Ensure it does not reappear on hover
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  }
};
